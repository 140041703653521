
export default defineNuxtPlugin(nuxtApp => {

  const runtimeConfig = useRuntimeConfig()
  
  // if we're not in development, AND 'logs' is not in the URL
  // if (runtimeConfig.public.ENV !== 'development' && !(window.location.href.search("logs") >=0 )) {
  //   // Disable console logs in production
  //   console.log(`I don't want no logs, a log is a guy that can't get no love from me...`);
    
  //   if (window) {
  //     window.console.log = function () { };
  //   }
  // }

})

