import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BEnDUEy4ze from "/app/node_modules/nuxt-unhead/dist/runtime/plugin.mjs";
import plugin_iGLjBbor9q from "/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import adminSettingscheck_client_wRmeo88klf from "/app/plugins/adminSettingscheck.client.ts";
import colorScheme_client_NWnrUWqPCg from "/app/plugins/colorScheme.client.ts";
import devicePixelRatio_client_puKznWhqt0 from "/app/plugins/devicePixelRatio.client.ts";
import globalTicker_client_gy66OydTx6 from "/app/plugins/globalTicker.client.ts";
import logManager_client_bml3icPSNL from "/app/plugins/logManager.client.ts";
import payload_reactivity_client_OIGps3SMvW from "/app/plugins/payload-reactivity.client.ts";
import posthog_client_fUucxKWhZE from "/app/plugins/posthog.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import simpleanalytics_client_JejRghgc0z from "/app/plugins/simpleanalytics.client.js";
import subdomain_OsqeBhtLtH from "/app/plugins/subdomain.ts";
import supabase_client_ddB06ekAgO from "/app/plugins/supabase.client.ts";
import versionCheck_client_KQq2FUvaVb from "/app/plugins/versionCheck.client.ts";
import vue_toastification_client_F29MfCWaUJ from "/app/plugins/vue-toastification.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BEnDUEy4ze,
  plugin_iGLjBbor9q,
  adminSettingscheck_client_wRmeo88klf,
  colorScheme_client_NWnrUWqPCg,
  devicePixelRatio_client_puKznWhqt0,
  globalTicker_client_gy66OydTx6,
  logManager_client_bml3icPSNL,
  payload_reactivity_client_OIGps3SMvW,
  posthog_client_fUucxKWhZE,
  sentry_client_shVUlIjFLk,
  simpleanalytics_client_JejRghgc0z,
  subdomain_OsqeBhtLtH,
  supabase_client_ddB06ekAgO,
  versionCheck_client_KQq2FUvaVb,
  vue_toastification_client_F29MfCWaUJ
]