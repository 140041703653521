import SimpleAnalytics from "simple-analytics-vue";


export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()

  if (runtimeConfig.public.ENV === "production") {
    nuxtApp.vueApp.use(SimpleAnalytics, {
      domain: "simple.chrisdermody.com"
    })
  }
});