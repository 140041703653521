
export default defineNuxtPlugin(async nuxtApp => {

  // This plugin is used to check the version of the app. When the version in the app is different from the version in the server, the app will prompt the user to update the app via a page refresh.

  const colorScheme = useColorScheme()

  let color = "myLight"

  if (typeof window !== 'undefined') {
    color = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'myDark' : 'myLight';
  }

  colorScheme.value = color

})

