import posthog from 'posthog-js'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const featureFlags = useFeatureFlags()
  const supabaseUser = useSupabaseUser()

  let allowedEnvs = ["production", "development"]

  // don't init posthog if we're in dev mode
  if (allowedEnvs.includes(runtimeConfig.public.ENV)) {

    posthog.init(runtimeConfig.public.POSTHOG_API_KEY, 
      { 
        api_host: 'https://eu.posthog.com',
        persistence: 'memory',
        disable_session_recording: true,
        opt_in_site_apps: true,
        loaded: function (posthog) {
          console.log("Posthog:: loaded:", posthog)
          // console.log('supabaseUser.value:', supabaseUser.value);
          
          console.log("Posthog:: Starting session recording")
          posthog.startSessionRecording();
          // if(supabaseUser.value) {
          //   // record authenticated sessions
            
            
          // } else {
          //   console.log("Posthog:: No user detected, not recording")
          // }
        }
      })
  }
  

  // posthog.onFeatureFlags(function() {
  //     // feature flags should be available at this point
  //     // if (posthog.isFeatureEnabled('show_model_preview_genders') ) {
  //     //     // do something
  //     //     featureFlags.value.show_model_preview_genders = true
  //     // }
  // })

  nuxtApp.provide('posthog', posthog)
});
