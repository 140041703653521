import { useHasBeenIdentified } from "./useState";
import { micromark as orignalMicromark} from 'micromark'
import metadata from '@/appVersion.json'
import { max } from "date-fns";

export const generateID = (length:number = 12, numberOnly: boolean = false) => {

  let alphabet
  if(numberOnly) {
    alphabet = '0123456789';
  } else {
    alphabet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  }

	var rtn = '';
	for (var i = 0; i < length; i++) {
	  rtn += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
	}
	return rtn; 
}


export const getLang = () => {
  return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
}

// export const getCookieSettings = () => {
// 	let localSettings = JSON.parse(localStorage.getItem("cookieConsent"))
// 	let settings = {
// 		completed: localSettings ? true : false,
// 		data: localSettings || {
// 			perf: false,
// 			req: true
// 		}
// 	}

// 	return settings
// }

// docs
// https://github.com/Maronato/vue-toastification
export const toast = useToast()

export const micromark = orignalMicromark

export const validatePermission = (permissionType, resourceType, enterpriseId = null) => {

  const supabaseUserData = useSupabaseUserData();

  const user_roles = supabaseUserData.value?.user_roles || []

  return user_roles.some(role => {

    // Check if enterpriseId is provided and matches, or if it's not provided at all
    const enterpriseMatch = enterpriseId === null || role.enterprise_id === enterpriseId;
    if (!enterpriseMatch) return false;

    // Check roles and permissions
    return role.roles.role_permissions.some(permission => {
      return permission.permissions.permission_type === permissionType &&
        permission.permissions.resource_type === resourceType;
    });
  });
}

export const sleep = (ms:number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const runJob = (jobID:string) => {
  console.log("running job:", jobID)
  const supabaseUserData = useSupabaseUserData()
  return new Promise(async(resolve, reject) => {
    const { $supabase } = useNuxtApp()
  
    let session = await $supabase.auth.getSession()
  
    try {
      let jobResponse = await $fetch('/api/run-job', {
        method: "POST",
        body:{
          jobID: jobID,
          session: session.data.session
        }
      })
  
      console.log('jobResponse', jobResponse);

      if(jobResponse.error) throw jobResponse.message

      // add the previews returned from API to the userData
      jobResponse?.data?.previews?.forEach(preview => {
        supabaseUserData.value.previews.unshift(preview)
      })
  
      toast.success('Previews are being generated')

      resolve(true)
      
    } catch (error) {
      console.log("Error running job:", error)
      // toast.error('Something went wrong: '+ JSON.stringify(error))
      reject(JSON.stringify(error))
    }
      
  });

}

export const identifyUser = async(userData:any) => {

  const hasBeenIdentified = useHasBeenIdentified()

  if(hasBeenIdentified.value) {
    console.log("User already identified")
  } else {
    
    console.log("Identifying user:", userData)

    const { $posthog } = useNuxtApp()
    
    try {

      if(!$posthog?.identify) throw "Posthog not loaded"
  
      $posthog.identify(userData.id)
  
      let logRequest = await $fetch('/api/log', {
        method: "POST",
        body:{
          type: 'identify',
          userID: userData.id,
          email: userData.email
        }
      })

      hasBeenIdentified.value = true
  
    } catch (error) {
      console.log("Error logging:", error)
    }
  }

}

export const trackEvent = async(event:{name:string, userID:string, properties?:object}) => {

	console.log("Tracking event:", event)

	try {
    let logRequest = await $fetch('/api/log', {
      method: "POST",
      body:{
				type: 'event',
				name: event.name,
        userID: event.userID,
				properties: event.properties || {}
      }
    })

  } catch (error) {
    console.log("Error logging:", error)
  }

}

export const downloadPreviewImages = async (preview) => {
  console.log("Downloading preview images:", preview)

  const { $supabase } = useNuxtApp()
  
  const previewBeingDownloaded = usePreviewBeingDownloaded()

  previewBeingDownloaded.value = preview.id

  // Call backend
  // show success or failure

  const supabaseUser = useSupabaseUser()

  let session = await $supabase.auth.getSession()

  try {
    let response = await fetch('/api/download-preview-images', {
      method: "POST",
      body: JSON.stringify({
        session: session.data.session,
        previewID: preview.id
      })
    })
  
    console.log('response:', response);

    let json = await response.json()

    // const blob = new Blob([json.data]);
    // const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    // a.href = downloadUrl;
    a.href = "data:application/zip;base64,"+json.data.zip;
    a.download = `${preview.style}-${preview.id}.zip`;
    document.body.appendChild(a);
    a.click();    

  } catch (error) {
    console.log("Error downloading preview images:", error)
    toast.error("Something went wrong!")

  } finally {
    setTimeout(()=>{
      previewBeingDownloaded.value = null
    },1500)
  }

}

export const getAdminStyles = async function(){

  const { $supabase } = useNuxtApp()

  const adminHairStyles = useAdminHairStyles()
  const hairstyleTags = useHairstyleTags()

  console.log("Geting admin hairstyles")

  adminHairStyles.value.status = 'loading'
  const { data, error } = await $supabase
    .from('prompts')
    .select(`*`)
    .order('created_at', { ascending: true })

  if(error) {
    console.log("Error getting styles:", error)
    adminHairStyles.value.status = 'error'
    adminHairStyles.value.error = error.message || error || "Unknown error"
  } 

  if(data) {
    // console.log('styles:', data);
    adminHairStyles.value.status = 'loaded'
    adminHairStyles.value.styles = data
  }
}

export const getAdminHairColours = async function () {

  const { $supabase } = useNuxtApp()

  const adminHairColours = useAdminHairColours()

  console.log("Geting admin haircolours")

  adminHairColours.value.status = 'loading'
  const { data, error } = await $supabase
    .from('haircolours')
    .select(`*`)
    .order('created_at', { ascending: true })

  if (error) {
    console.log("Error getting colours:", error)
    adminHairColours.value.status = 'error'
    adminHairColours.value.error = error.message || error || "Unknown error"
  }

  if (data) {
    // console.log('styles:', data);
    adminHairColours.value.status = 'loaded'
    adminHairColours.value.colours = data
  }
}

export const handleGoogleCredentialResponse = async function (response) {
  
  console.log("handleCredentialResponse:", response)

  const { $supabase } = useNuxtApp()

  const { data, error } = await $supabase.auth.signInWithIdToken({
    token: response.credential,
    provider: 'google'
  })

  if(error) {
    console.log("Error signing in with Google:", error)
    toast.error("Something went wrong!")
  }

  if(data && data.user && data.session) {
    const supabaseUser = useSupabaseUser()
    supabaseUser.value = data.session.user

    identifyUser(data.session.user)

    await getUserData(data.session.user.id)

    // start listening for realtime events
    initRealtimeListening()
    console.log("Google sign in successful:", data)
    toast.success("Signed in successfully!")
  }
  
}

export const getAdminJobs = async function(fromValue = 0, jobID = null, jobSearch = {text: '', type: 'all'}){

  const { $supabase } = useNuxtApp()
  const adminJobs = useAdminJobs()

  console.log("Getting admin jobs")
  // log the arguments
  console.log("fromValue:", fromValue)
  console.log("jobID:", jobID)
  console.log("jobSearch:", jobSearch)

  adminJobs.value.status = 'loading'

  let query = $supabase
    .from('jobs')
    .select(`
      *,
      profiles_private (
        images, 
        email
      ),
      previews (
        id,
        status,
        style,
        created_at,
        ik_images,
        colour,
        errors,
        result_data
      )
    `)
    .range(fromValue, fromValue + 1)
    .limit(20)
    .order('created_at', { foreignTable: 'previews', ascending: false })
    .order('created_at', { ascending: false })

  // if jobSearch.text is not empty, add a filter
  if (jobSearch.text.length) {
    query = query.or(`profiles_private:email.ilike.${jobSearch.text}%`)
  }

  // same again for status
  if (jobSearch.type !== 'all') {
    query = query.eq('status', jobSearch.type)
  }

  // if jobID is provided, get that job
  if(jobID) {
    query = query.eq('id', jobID)
  }

  const { data, error } = await query

  if (error) {
    console.log("Error getting jobs:", error)
    return
  }

  if (data) {
    console.log('jobs retrieved:', data);
    console.log('adminJobs.value:', adminJobs.value);
    
    data.forEach(result => {
      if (result && result.id && adminJobs.value) {
        adminJobs.value.jobs[result.id] = result;
        adminJobs.value.lastUpdated = new Date().getTime()
      }
    })
  }

  adminJobs.value.status = 'waiting'

}

export const initRealtimeListening = async function () {

  const { $supabase } = useNuxtApp()
  const supabaseUser = useSupabaseUser()
  const supabaseUserData = useSupabaseUserData()
  const adminJobs = useAdminJobs()

  let realtimeQuery = $supabase.channel('changes')

  console.log("Initing realtime listening")
  // listen for job updates if I'm an admin or have the right role
  if (validatePermission('view', 'admin_area')) {

    console.log("initiating admin listening")

    realtimeQuery = realtimeQuery.on('postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'jobs',
      },
      payload => {
        console.log('Jobs change received:', payload.eventType)
        console.log('Jobs change data:', payload)

        // if it's an update or insert, update the adminJobs
        if(['INSERT', 'UPDATE'].includes(payload.eventType)) {
          getAdminJobs(0, payload.new.id)
        }

        // if it's a delete, remove it from adminJobs
        if(payload.eventType == 'DELETE') {
          delete adminJobs.value.jobs[payload.old.id]
        }

      })
    
  }


  realtimeQuery = realtimeQuery
    .on('postgres_changes',
      {
        event: 'update',
        schema: 'public',
        table: 'accounts',
        filter: 'id=eq.' + supabaseUser.value.id
      },
      payload => {
        console.log('Accounts change received:', payload)

        console.log('supabaseUserData.value:', supabaseUserData.value);
        supabaseUserData.value.accounts.credit = payload.new.credit
      })
    .on('postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'previews',
      },
      payload => {
        console.log('previews change received!', payload)

        console.log('supabaseUserData.value:', supabaseUserData.value);

        // check if user owns the preview. 
        // if they don't, then they're an admin or org user

        if(payload.new.owner == supabaseUser.value.id){ 
          
          // user owns this preview
          console.log("User owns this preview")

          // find this preview in the user's previews
          // if it's not there, add it
          // if it's there, update it
          const previews = useUserPreviews()
          console.log("previews.value:", previews.value);

          // previews is an object, set this on the object using the id as the key

          previews.value[payload.new.id] = payload.new


        } else {

          console.log("User does not own this preview - must be an admin")

          // update adminJobs
          let foundJob = adminJobs.value.jobs[payload.new.job_id]

          if(foundJob) {
            // find the preview in the job
            
            let foundIndex = foundJob.previews.findIndex(prev => prev.id == payload.new.id)

            // console.log("foundIndex:", foundIndex);

            if(foundIndex > -1) {
              adminJobs.value.jobs[payload.new.job_id].previews.splice(foundIndex, 1, payload.new)
              console.log("Preview should be updated: ", adminJobs.value.jobs[payload.new.job_id].previews[foundIndex])
            } else {
              adminJobs.value.jobs[payload.new.job_id].previews.push(payload.new)
            }

        }
      }

    })
    .on('postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'notifications',
      },
      payload => { 

        // log it
        console.log('notifications change received!', payload)

        // find the notification in the user's notifications
        // if it's not there, add it
        // if it's there, update it
        let found = supabaseUserData.value.notifications.find(notif => notif.id == payload.new.id)

        if(found) {
          // update it
          let foundIndex = supabaseUserData.value.notifications.findIndex(notif => notif.id == payload.new.id)
          supabaseUserData.value.notifications.splice(foundIndex, 1, payload.new)
        }
        else {
          // add it
          supabaseUserData.value.notifications.unshift(payload.new)
        }
      })
    .on('postgres_changes',
      {
        event: 'update',
        schema: 'public',
        table: 'profiles_private',
        filter: 'id=eq.' + supabaseUser.value.id
      },
      payload => {
        console.log('Profile change received:', payload)

        if (payload.new.images) {

          let keys = Object.keys(payload.new.images)

          keys.forEach(key => {
            // console.log("key:", key)
            supabaseUserData.value.images[key] = payload.new.images[key]          
          })
        }
      })

  realtimeQuery.subscribe()

}

export const dataURLtoBlob = function(dataurl) {
  const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export const isTouchDevice = function () {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}

export const handleHairstyleTags = async function (styles) {

  // is used by different pages to get the tags for hairstyles and update the store
  const hairstyleTags = useHairstyleTags()

  let tags = []

  styles.forEach(style => {
    if(style.tags) tags = tags.concat(style.tags.split(","))
  })

  // remove duplicates
  tags = tags.filter((item, index) => tags.indexOf(item) === index)

  hairstyleTags.value = tags


}

export const generateImagePreviewURL =  function (imageData) {

  // console.log("Generating image preview URL:", imageData)

  const selectedGender = useSelectedGender()
  // const selectedEthnicity = useSelectedEthnicity()

  // using the user's selected gender and ethnicty, return the correct image
  // structure of imageData is
  // "man-asian": "",
  // "man-black": "",
  // "man-white": "https://ik.imagekit.io/aicosmetic/AAA-hairstyle_images/homepage/113-preview-625242599894-4783_OwJldEkk5?updatedAt=1698597802200",
  // "man-indian": "",
  // "woman-asian": "",
  // "woman-black": "",
  // "woman-white": "https://ik.imagekit.io/aicosmetic/AAA-hairstyle_images/models/slicked-back-undercut1.webp?updatedAt=1693672961398",
  // "woman-indian": ""

  // .replace('https://ik.imagekit.io/aicosmetic/','') ||'/AAA-hairstyle_images/models/coming-soon.png?updatedAt=1693172241302

  let url = ""

  // if selectedGender is "all" or not set, return female
  if(!selectedGender.value || selectedGender.value == "all") {
    // check if imageData["woman-white"] exists, if it does, use it, if it doesn't, use man-white
    url = imageData["woman-white"] || imageData["man-white"]
  } else {
    // if selectedGender is woman or man, we need to make it masculine or feminine
    if(["masculine", "feminine"].includes(selectedGender.value)) {
      let keyObject = {
        "masculine": "man",
        "feminine": "woman"
      }

      url = imageData[keyObject[selectedGender.value]+"-white"]
    } else {
      url = imageData[selectedGender.value+"-white"]
    }
  }

  // strip out the imagekit domain
  url = url?.replace('https://ik.imagekit.io/aicosmetic/','') ||'/AAA-hairstyle_images/models/coming-soon.png?updatedAt=1693172241302'

  // for whatever gender the user has selected, append it with "-white" to get the image
  return url  

}

export const checkVersion = async function () {

  console.log("Checking app version")

  const supabaseUser = useSupabaseUser()
  const appVersionStatus = useAppVersionStatus()

  // API GET request to /api/version with current version of the app in query params
  // Use fetch to get the version of the app
  let response = await fetch(`/api/version`)

  // check if the response is ok
  if (!response.ok) {
    console.error(`Error fetching version`)
    return
  }

  // parse the response as json
  let data = await response.json()

  // check if the version of the app is different from the version in the server
  if (data.build !== metadata.build) {

    // console.log(`Build mismatch`)

    // send signal to prompt user to reload if they haven't already ignored it
    if (appVersionStatus.value !== 'ignored') {
      appVersionStatus.value = 'warnUser'
      trackEvent({
        name: "Build Mismatch, user notified to refresh page",
        userID: supabaseUser.value?.id || null,
        properties: {
          currentBuild: metadata.build,
          serverBuild: data.build
        }
      })
    }

  }
}



export const deleteImageFromStorage = async function (options:object) {

  console.log("Deleting image from storage:", options);

  // make DELETE request to /api/image with imageURL in body

  let response = await fetch(`/api/delete-image`, {
    method: "POST",
    body: JSON.stringify({
      session: options.session,
      fileId: options.image.fileId
    })
  })

  // check if the response is ok
  if (!response.ok) {
    console.error(`Error deleting image`)
    return
  }

  // parse the response as json
  let data = await response.json()

  return data

}

export const watermarkModifiers = function (watermark) {

  // This function returns a string of modifiers for use when a custom watermark is being applied to an image

  let transformation = {}

  if(watermark) {
    transformation = {
      raw: ":"+watermark
    }
  } else {
    
    transformation = {
      n: "wm-01"
    }
  }

  console.log("Watermark modifiers:", transformation)

  return transformation
}

export const imageURLGenerator = function (baseURL, options) {

  // console.log("Generating image URL:", baseURL)
  // console.log("Options:", options)  

  // we always want to add dpr-auto anyways
  let appendor = baseURL.includes("?") ? ":" : "?tr="
  
  let url = baseURL + appendor + "dpr-auto"

  // for each property on the options object, add it to the URL by taking the key and value and adding it to the URL
  Object.keys(options).forEach(key => {
    
    // if this is rawString and it has no value, continue
    if (key == "rawString" && options[key] === undefined) {
      // there is no user specific watermark, use AIhairstyles
      url += ":n-wm01"
    } else if (key == "rawString") { 
      url += ":" + options[key]
    } else {
      url += "," + key + "-" + options[key]
    }
  })

  //   "custom_watermark": "l-image,i-36cc1e3c-cb82-4fa6-8b7b-6b5b39394920@@Ruso1,lfo-bottom_right,w-bw_mul_0.4,l-end"

  // console.log("Generated URL:", url)
  
  return url

}

export const generationsFromPreviews = function (previews) {

  console.log('processing previews to generations:', previews);

  if (!previews) return []

  // create a single array of all the images from all the previews
  let generations = []

  for (let key in previews) {

    if (previews.hasOwnProperty(key)) {

      let preview = previews[key];

      // The number of times to loop is either the image_count, ik_images.length or 1, whatever is the largest
      let loopCount = Math.max(preview.ik_images ? preview.ik_images.length : 0, preview.result_data ? Object.keys(preview.result_data).length : 1, preview.image_count);

      // we need to loop for as many times as there is image_count
      for (let i = 0; i < loopCount; i++) {

        let key = preview.result_data ? Object.keys(preview.result_data)[i] : null;
        let images = preview.result_data ? preview.result_data[key] : null;

        // for older customers, images will be null, but ik_images will be populated
        if (!images && preview.ik_images) {
          images = {
            final: {
              imagekit_url: preview.ik_images[i]
            }
          }
        }

        // if preview.result_data[key]['deleted'] is true, skip this generation
        if(images && images['deleted']) continue;

        generations.push({
          preview_id: preview.id,
          generation_key: key,
          images: images,
          ik_images: preview.ik_images,
          created_at: preview.created_at,
          style: preview.style,
          colour: preview.colour,
          error: preview.errors?.length ? preview.errors[0] : null
        })
      }
    }
  }

  // sort the images by created_at
  generations.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  // generations = generations.filter(generation => generation.state == "loading")

  // console.log('generations:', generations);

  return generations

}