<template>

  <dialog
    ref="confirmModal"id="confirmModal"
    class="modal modal-bottom sm:modal-middle"
    @close="modalClosed"
  >

    <div class="modal-box">
      <form method="dialog">
        <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
      </form>
      <h3 class="font-bold text-lg">
        {{ title }}
      </h3>
      <p class="py-4">
        {{ message}}
      </p>
      <div class="modal-action">
        <label
          for="my-modal-6"
          class="btn btn-ghost"
          @click="closeModal"
        >{{ ignoreLabel }}</label>
        <label
          for="my-modal-6"
          class="btn btn-primary"
          @click="confirmAction"
        >{{ confirmLabel }}</label>
      </div>
    </div>

    <form
      method="dialog"
      class="modal-backdrop"
    >
      <button>close</button>
    </form>


  </dialog>




</template>

<style scoped>

  
</style>

<script setup>

const confirmModal = ref(null)
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  },
  confirmLabel: {
    type: String,
    required: false,
    default: "Confirm"
  },
  confirmAction: {
    type: Function,
    required: true
  },
  data: {
    type: Object,
    required: false
  },
  ignoreLabel: {
    type: String,
    required: false,
    default: "Cancel"
  }
})

const emit = defineEmits(['closeModal'])

onMounted(() => {
  console.log("Confirm modal mounted, props:", props)
  setTimeout(() => {
    confirmModal.value.showModal()
  }, 100)
})

function closeModal() {
  console.log("Closing alert modal")
  confirmModal.value.close()
}

function modalClosed() {
  console.log("alert modal closed")

  setTimeout(() => {
    emit('closeModal')
  }, 200)
}

</script>