// TUTORIAL https://youtu.be/NoJa7FKqmhU?t=3558

export const useAuthChecked = () => useState<any>('authChecked', () => false)

export const useSupabaseUser = () => useState<any>('supabaseUser', () => null)

export const useSupabaseUserData = () => useState<any>('supbaseUserData', () => null)

export const useUserPreviews = () => useState<any>('userPreviews', () => { return {}})

export const useUserPurchases = () => useState<any>('userPurchases', () => null)

export const useAdminUserSearchResults = () => useState<any>('adminUserSearchResults', () => { return {}})

export const useAdminJobs = () => useState<any>('adminJobs', () => { return {
  'status': 'waiting',
  'jobs': {},
  'lastUpdated': null,
} })

export const useAdminPreviews = () => useState<any>('adminPreviews', () => { return {}})

export const useAdminEmails = () => useState<any>('adminEmails', () => { return {}})

export const useAdminAffiliates = () => useState<any>('adminaffiliates', () => { return {} })

export const useAdminSettings = () => useState<any>('adminSettings',  () => {
  return {
    showJSON: false
  }
})

export const useTicker = () => useState<any>('ticker', () => new Date().getTime())

export const useHasBeenIdentified = () => useState<boolean>('hasBeenIdentified', () => { return false })

export const useColorScheme = () => useState<any>('colorScheme',  () => {
  return 'myDark'
})

export const usePreviewBeingDownloaded = () => useState<any>('previewBeingDownloaded',  () => null)

export const useSubdomain = () => useState<any>("subdomain", () => null)

export const useEnterpriseData = () => useState<any>("subdomainData", () => {
  null
})


export const useAdminHairStyles = () => useState<any>('adminHairStyles', () => {
  return {
    'status': 'waiting',
    'styles': [],
    'error': null
  }
})

export const useAdminHairColours = () => useState<any>('adminHairColours', () => {
  return {
    'status': 'waiting',
    'colours': [],
    'error': null
  }
})

export const useFeatureFlags = () => useState<any>('featureFlags', () => {
  return {

  }
})

export const useSelectedGender = () => useState<any>('selectedGender', () => {
  return null
})

export const useSelectedEthnicity = () => useState<any>('selectedEthnicity', () => {
  return 'all'
})

export const useSelectedImage = () => useState<any>('selectedImage', () => {
  return null
})

export const useReferralCode = () => useState<any>('referralCode', () => {
  return null
})

export const useAffiliatePromoCode = () => useState<any>('affiliatePromoCode', () => {
  return null
})

// export a const called clientSecret
export const useClientSecret = () => useState<any>('clientSecret', () => {
  return null
})

export const useHairstyleTags = () => useState<any>('hairstyleTags', () => {
  return []
})

export const useProducts = () => useState<any>('packages', () => {
  const config = useRuntimeConfig().public
  return {
    0: {
      "code": config.STRIPE_CREDIT_SINGULAR_0069,
      "title": "69 cents per credit",
      "description": "Straight credits",
      "price": 0.69,
      "creditValue": 1,
      "type": "credit",
      "minCreditPurchase": 10
    },
    1: {
      "code": config.STRIPE_CREDIT_SINGULAR_0049,
      "title": "49 cents per credit",
      "description": "Straight credits",
      "price": 0.49,
      "creditValue": 1,
      "type": "credit",
      "minCreditPurchase": 50
    },
    2: {
      "code": config.STRIPE_CREDIT_SINGULAR_0039,
      "title": "39c per credit",
      "description": "Straight credits",
      "price": 0.39,
      "creditValue": 1,
      "type": "credit",
      "minCreditPurchase": 150
    },
    3: {
      "code": config.STRIPE_ALL_STYLES_PACKAGE,
      "stripe_metadata_name": "all_styles_all_colours",
      "title": "Every style, all colours",
      "description": "",
      "price": 25,
      "creditValue": null,
      "type": "package",
      "descriptionParagraphs": [
        `You'll see yourself in every style, in every colour we currently have available`
      ]
    },
    4: {
      "code": config.STRIPE_ALL_STYLES_ONE_COLOUR_PACKAGE,
      "stripe_metadata_name": "all_styles_one_colour",
      "title": "Every style, one colour",
      "description": "",
      "descriptionParagraphs": [
        `You'll see yourself in all styles, in one colour of your choice`
      ],
      "price": 8,
      "creditValue": null,
      "type": "package",
    },

  }
})

export const useAppVersionStatus = () => useState<string>('appVersionStatus', () => {
  return 'matched'
})

export const useIsMobile = () => useState<boolean>('isMobile', () => {
  return true
})