// In useState.ts, we have adminSettings. 
// This plugin will check if the adminSettings are set, and if not, will set them to the default values.
// Only the client side is checked, as the server side will always have the default values.


export default defineNuxtPlugin(async nuxtApp => {
  
  const adminSettings = useAdminSettings()

  let storedAdminSettings = sessionStorage.getItem('adminSettings')
  if (storedAdminSettings) {
    adminSettings.value = JSON.parse(storedAdminSettings)
  }


});