
// The purpose of this plugin is to detect if we're on a customer's subdomain or not, and if we are, to fetch the enterprise data for that subdomain. 
// We need to do this because we have a multi-tenant application, and we need to know which tenant the user is on in order to fetch the correct data.
// We need to handle IP addresses too for local development



export default defineNuxtPlugin(async nuxtApp => {
  
  const subDomain = useSubdomain()
  const enterpriseData = useEnterpriseData()

  let host
  if(process.server) {
    // we can get the subdomain from the event context, set in server middleware
    // console.log('nuxtApp.ssrContext?.event.node.req.headers:', nuxtApp.ssrContext?.event.node.req.headers);
    // console.log("subdomain plugin: server")
    
    host = nuxtApp.ssrContext?.event.node.req.headers.host
  } else {
    host = window.location.host
    // console.log("subdomain plugin: client")
  }

  console.log('host:', host);
  
  if(!host) return

  // console.log('host:', host);
  const mainDomains = [
    "localhost",
    "localhost:3000",
    "localhost:3001",
    "aihairstyles.com",
    "www.aihairstyles.com",
    "staging.aihairstyles.com",
    "aihairstyles.lhr.rocks",
    '192.168.107.64:3000'
  ]


  // Add a regular expression to match IP addresses
  const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(:\d+)?$/;
  
  if (!mainDomains.includes(host) && !ipRegex.test(host)) {

    // we know we're on a subdomain
    subDomain.value = host?.split(".")[0]

    // console.log('plugin: subdomain set to:', subDomain.value);
    let { data: enterpriseDataResponse } = await useFetch(`/api/enterprise?name=${subDomain.value}`)

    // console.log('enterpriseDataResponse.value:', enterpriseDataResponse.value);
    
    if(enterpriseDataResponse.value?.data) {
      enterpriseData.value = enterpriseDataResponse.value.data 
    } else {
      console.log("No data retrieved for:", subDomain.value )
    }
    
  }

});