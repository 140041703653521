
export const getUserPurchases = async (userID:string) => {
  
  console.log("Getting user purchases:", userID)
  const supabaseUser = useSupabaseUser()
  const { $supabase } = useNuxtApp()
  const purchases = useUserPurchases()

  return new Promise(async(resolve, reject) => {

    const { data, error } = await $supabase
    .from('purchases')
    .select(`*`)
    .order('created_at', { ascending: false })
    .eq('owner', userID)
    .limit(10)
  
    if(error) {
      console.log("Error getting purchases:", error)
    } 
  
    if(data) {
      console.log(`Purchases:`, data);
      
      purchases.value = data
    }

    resolve(data)

  })

}

// a function that get's more of the uer's previews, taking a limit and offset as well as a userID
export const getUserPreviews = async (userID:string, limit:number, offset:number) => {
  
  console.log("Getting user previews:", userID)
  
  const { $supabase } = useNuxtApp()
  const previews = useUserPreviews()

  console.log("previews:", previews.value)

  return new Promise(async(resolve, reject) => {

    const { data, error } = await $supabase
    .from('previews')
    .select(`*`)
    .range(offset, offset + limit - 1)
    .order('created_at', { ascending: false })
    .eq('owner', userID)
    .limit(limit)
  
    if(error) {
      console.log("Error getting previews:", error)
    } 
  
    if(data) {
      console.log(`Previews:`, data);

      // for each preview, set it on the previews object
      data.forEach(preview => {
        previews.value[preview.id] = preview
      })
      
    }

    resolve(data.length || 0)

  })

}

export const getUserData = async (userID:string) => {
  console.log("getting user profile data, userID:", userID)

  return new Promise(async (resolve, reject) => {
    
    const { $supabase } = useNuxtApp()
    const supabaseUserData = useSupabaseUserData()
    const selectedGender = useSelectedGender()
  
    const { data, error } = await $supabase
      .from('profiles_private')
      .select(`
        images, 
        email, 
        preferred_gender,
        preferred_hair_colour,
        ethnicity,
        email_preferences,
        personal_settings,
        referral_code,
        account_status,
        affiliate_promo_code,
        accounts (
          credit
        ),
        jobs (
          id, data, status, tune_id
        ),
        previews (
          count
        ),
        notifications (
          id,
          data,
          type,
          created_at
        ),
        affiliates (
          id, promo_code
        ),
        purchases (
          id,
          created_at,
          payment_data->amount_total,
          payment_data->currency,
          data
        ),
        api_keys (
          id,
          key
        ),
        user_roles (
          enterprise_id,
          role_id,
          roles (
            role_name,
            role_permissions (
              permissions (
                permission_type,
                resource_type,
                description
              )
            )
          )
        )
        `, { count: 'exact' })
      .eq('id', userID)
      .eq('jobs.owner', userID)
      .eq('previews.owner', userID)
      .eq('affiliates.owner', userID)
      .eq('purchases.owner', userID)
      .eq('api_keys.owner', userID)
      .order(`id`, { foreignTable: 'jobs', ascending: false})
      .order(`created_at`, { foreignTable: 'notifications', ascending: false })
      .order(`created_at`, { foreignTable: 'purchases', ascending: false })
      .limit(10, { foreignTable: 'notifications' })
    
    if(error) {
      console.log("Error getting user data:", error)
      toast.error('Something went wrong - contact support')
    } else {

      console.log('AUTH data:', data);

      // Adjust here to handle potentially multiple records
      if (data && data.length > 0) {
        
        supabaseUserData.value = data[0];
      } else {
        // Handle case where no data is returned
        supabaseUserData.value = {};
      }
    
      console.log("all user data is now:", {...supabaseUserData.value})
  
      selectedGender.value = supabaseUserData.value.preferred_gender
  
      resolve(supabaseUserData.value)
    }

  })

}


interface SaveUserDataOptions {
  showSuccessToast: boolean,
}

export async function saveUserData (options:SaveUserDataOptions = {
  showSuccessToast: false
}) {
  
  const { $supabase } = useNuxtApp()
  const supabaseUser = useSupabaseUser()
  const supabaseUserData = useSupabaseUserData()

  if(!supabaseUser.value) {
    // we've no user, abort
    console.log("no user, abort")
    return
  }
  
  console.log(`Saving userData for ${supabaseUser.value.id}, options:`, options)
  
  let newData = {...supabaseUserData.value}
  if(newData.roles) {
    delete newData.roles // roles doesn't exist in the table we're updatin'
  }
  delete newData.user_roles // user_roles is in a different table
  delete newData.accounts // accounts is a different table that has been merged in to local user data
  delete newData.tunes // tunes is in a different table
  delete newData.jobs // jobs is a different table
  delete newData.previews // previews are in a different table
  delete newData.notifications // notifications are in a different table
  delete newData.affiliates // affiliates are in a different table
  delete newData.purchases // purchases are in a different table
  delete newData.api_keys // api_keys are in a different table

  console.log('newData:', newData);
  

  const { data, error } = await $supabase
    .from('profiles_private')
    .update(newData)
    .match({id: supabaseUser.value.id })


  if(error){
    console.log('error:', error)
  } else {
    console.log("user data saved")
    if(options.showSuccessToast) toast.success("Saved!")
  }
  
}

export const signOut = async (options) => {
  console.log("Signing out")

  const { $supabase } = useNuxtApp()
  const supabaseUser = useSupabaseUser()
  const supabaseUserData = useSupabaseUserData()

  try {
    const { error } = await $supabase.auth.signOut()

    if(error) throw error
    // clear user data from state
    supabaseUser.value = null
    supabaseUserData.value = null
    console.log("user logged out")
    toast.success("Logged out")
    navigateTo({
      path: options.redirect || '/'
    })
  } catch (error) {
    console.log("Error signing out:", error)
    alert(error)
  }
}