import { default as account_45deletedH3dXQfY2FlMeta } from "/app/pages/account-deleted.vue?macro=true";
import { default as _91affiliate_93bnXmUEObJqMeta } from "/app/pages/admin/affiliates/[affiliate].vue?macro=true";
import { default as indexVcrGIYPBzqMeta } from "/app/pages/admin/affiliates/index.vue?macro=true";
import { default as _91colour_936Vo7PMtrcQMeta } from "/app/pages/admin/colours/[colour].vue?macro=true";
import { default as index6cFuaLnFn0Meta } from "/app/pages/admin/colours/index.vue?macro=true";
import { default as crons1U8occi3pgMeta } from "/app/pages/admin/crons.vue?macro=true";
import { default as indexTxw9OmWez7Meta } from "/app/pages/admin/emails/index.vue?macro=true";
import { default as templatesObN327vMkaMeta } from "/app/pages/admin/emails/templates.vue?macro=true";
import { default as _91job_93PuNpq4nJPUMeta } from "/app/pages/admin/jobs/[job].vue?macro=true";
import { default as indexQHdfyf6yDvMeta } from "/app/pages/admin/jobs/index.vue?macro=true";
import { default as main_45dashboardrjSGcUKzj9Meta } from "/app/pages/admin/main-dashboard.vue?macro=true";
import { default as _91preview_933D9HGh3vtxMeta } from "/app/pages/admin/previews/[preview].vue?macro=true";
import { default as indexQAL4hV4WpQMeta } from "/app/pages/admin/previews/index.vue?macro=true";
import { default as purchasesdA0Gx4kBRJMeta } from "/app/pages/admin/purchases.vue?macro=true";
import { default as roles_45permissionsx1NGEpCBHxMeta } from "/app/pages/admin/roles-permissions.vue?macro=true";
import { default as _91style_93UhMeSA8SjPMeta } from "/app/pages/admin/styles/[style].vue?macro=true";
import { default as indexMXfnlM4lNpMeta } from "/app/pages/admin/styles/index.vue?macro=true";
import { default as _91user_93MHSmAjsHPwMeta } from "/app/pages/admin/users/[user].vue?macro=true";
import { default as indexYm9dzWmM2AMeta } from "/app/pages/admin/users/index.vue?macro=true";
import { default as _91affiliate_id_93onHUIyiEZ2Meta } from "/app/pages/affiliate/dashboard/[affiliate_id].vue?macro=true";
import { default as indextwBLUNZtAmMeta } from "/app/pages/affiliate/index.vue?macro=true";
import { default as sign_45upWH3WycuEGRMeta } from "/app/pages/affiliate/sign-up.vue?macro=true";
import { default as ai_45face_45shape_45analysis_45hairstyle_45recommendationsUbokCahJHzMeta } from "/app/pages/ai-face-shape-analysis-hairstyle-recommendations.vue?macro=true";
import { default as ai_45hair_45consultationbUo1zokiatMeta } from "/app/pages/ai-hair-consultation.vue?macro=true";
import { default as api5wy6uy1fBRMeta } from "/app/pages/api.vue?macro=true";
import { default as auth7tE1sZcZDUMeta } from "/app/pages/auth.vue?macro=true";
import { default as _91business_id_937AHarsxxx9Meta } from "/app/pages/barbers/dashboard/[business_id].vue?macro=true";
import { default as indexkA5moxCFvMMeta } from "/app/pages/barbers/index.vue?macro=true";
import { default as sign_45upvv9s8aVZAWMeta } from "/app/pages/barbers/sign-up.vue?macro=true";
import { default as beardsZFdr6qF4GvMeta } from "/app/pages/beards.vue?macro=true";
import { default as _91_46_46_46slug_93jK3Pyj8dSOMeta } from "/app/pages/blog/[...slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as businessl9xJeDqVoeMeta } from "/app/pages/business.vue?macro=true";
import { default as confirmingj4YZSMsjbAMeta } from "/app/pages/confirming.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as cookie_45policy2bZRk7g5I8Meta } from "/app/pages/cookie-policy.vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as hair_45clinics7FuHvELoMGMeta } from "/app/pages/hair-clinics.vue?macro=true";
import { default as index2tFFN1v1P9Meta } from "/app/pages/hair-colour-changer/index.vue?macro=true";
import { default as hairHDygVwuo6tMeta } from "/app/pages/hair.vue?macro=true";
import { default as _91style_936wpj7tFtkYMeta } from "/app/pages/hairstyles/[style].vue?macro=true";
import { default as indexYWfNFCnYq8Meta } from "/app/pages/hairstyles/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91preview_93TMcwzYYBU4Meta } from "/app/pages/previews/[preview].vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as _91user_93Agkyk5NCc7Meta } from "/app/pages/profile/[user].vue?macro=true";
import { default as referredcP1rFpbNQjMeta } from "/app/pages/referred.vue?macro=true";
import { default as requestDDjKx5DQJbMeta } from "/app/pages/request.vue?macro=true";
import { default as salonsuONQu7qc5dMeta } from "/app/pages/salons.vue?macro=true";
import { default as studioyhbHREGAZRMeta } from "/app/pages/studio.vue?macro=true";
import { default as termsy18ZESLqu6Meta } from "/app/pages/terms.vue?macro=true";
import { default as visualizereRaV0ziYBBMeta } from "/app/pages/visualizer.vue?macro=true";
export default [
  {
    name: "account-deleted",
    path: "/account-deleted",
    component: () => import("/app/pages/account-deleted.vue").then(m => m.default || m)
  },
  {
    name: "admin-affiliates-affiliate",
    path: "/admin/affiliates/:affiliate()",
    component: () => import("/app/pages/admin/affiliates/[affiliate].vue").then(m => m.default || m)
  },
  {
    name: "admin-affiliates",
    path: "/admin/affiliates",
    component: () => import("/app/pages/admin/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-colours-colour",
    path: "/admin/colours/:colour()",
    component: () => import("/app/pages/admin/colours/[colour].vue").then(m => m.default || m)
  },
  {
    name: "admin-colours",
    path: "/admin/colours",
    component: () => import("/app/pages/admin/colours/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-crons",
    path: "/admin/crons",
    component: () => import("/app/pages/admin/crons.vue").then(m => m.default || m)
  },
  {
    name: "admin-emails",
    path: "/admin/emails",
    component: () => import("/app/pages/admin/emails/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-emails-templates",
    path: "/admin/emails/templates",
    component: () => import("/app/pages/admin/emails/templates.vue").then(m => m.default || m)
  },
  {
    name: "admin-jobs-job",
    path: "/admin/jobs/:job()",
    component: () => import("/app/pages/admin/jobs/[job].vue").then(m => m.default || m)
  },
  {
    name: "admin-jobs",
    path: "/admin/jobs",
    component: () => import("/app/pages/admin/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-main-dashboard",
    path: "/admin/main-dashboard",
    component: () => import("/app/pages/admin/main-dashboard.vue").then(m => m.default || m)
  },
  {
    name: "admin-previews-preview",
    path: "/admin/previews/:preview()",
    component: () => import("/app/pages/admin/previews/[preview].vue").then(m => m.default || m)
  },
  {
    name: "admin-previews",
    path: "/admin/previews",
    component: () => import("/app/pages/admin/previews/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-purchases",
    path: "/admin/purchases",
    component: () => import("/app/pages/admin/purchases.vue").then(m => m.default || m)
  },
  {
    name: "admin-roles-permissions",
    path: "/admin/roles-permissions",
    component: () => import("/app/pages/admin/roles-permissions.vue").then(m => m.default || m)
  },
  {
    name: "admin-styles-style",
    path: "/admin/styles/:style()",
    component: () => import("/app/pages/admin/styles/[style].vue").then(m => m.default || m)
  },
  {
    name: "admin-styles",
    path: "/admin/styles",
    component: () => import("/app/pages/admin/styles/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-user",
    path: "/admin/users/:user()",
    component: () => import("/app/pages/admin/users/[user].vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/app/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "affiliate-dashboard-affiliate_id",
    path: "/affiliate/dashboard/:affiliate_id()",
    component: () => import("/app/pages/affiliate/dashboard/[affiliate_id].vue").then(m => m.default || m)
  },
  {
    name: "affiliate",
    path: "/affiliate",
    component: () => import("/app/pages/affiliate/index.vue").then(m => m.default || m)
  },
  {
    name: "affiliate-sign-up",
    path: "/affiliate/sign-up",
    component: () => import("/app/pages/affiliate/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "ai-face-shape-analysis-hairstyle-recommendations",
    path: "/ai-face-shape-analysis-hairstyle-recommendations",
    component: () => import("/app/pages/ai-face-shape-analysis-hairstyle-recommendations.vue").then(m => m.default || m)
  },
  {
    name: "ai-hair-consultation",
    path: "/ai-hair-consultation",
    component: () => import("/app/pages/ai-hair-consultation.vue").then(m => m.default || m)
  },
  {
    name: "api",
    path: "/api",
    component: () => import("/app/pages/api.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/app/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "barbers-dashboard-business_id",
    path: "/barbers/dashboard/:business_id()",
    component: () => import("/app/pages/barbers/dashboard/[business_id].vue").then(m => m.default || m)
  },
  {
    name: "barbers",
    path: "/barbers",
    component: () => import("/app/pages/barbers/index.vue").then(m => m.default || m)
  },
  {
    name: "barbers-sign-up",
    path: "/barbers/sign-up",
    component: () => import("/app/pages/barbers/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "beards",
    path: "/beards",
    component: () => import("/app/pages/beards.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("/app/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "business",
    path: "/business",
    component: () => import("/app/pages/business.vue").then(m => m.default || m)
  },
  {
    name: "confirming",
    path: "/confirming",
    component: () => import("/app/pages/confirming.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "hair-clinics",
    path: "/hair-clinics",
    component: () => import("/app/pages/hair-clinics.vue").then(m => m.default || m)
  },
  {
    name: "hair-colour-changer",
    path: "/hair-colour-changer",
    component: () => import("/app/pages/hair-colour-changer/index.vue").then(m => m.default || m)
  },
  {
    name: "hair",
    path: "/hair",
    component: () => import("/app/pages/hair.vue").then(m => m.default || m)
  },
  {
    name: "hairstyles-style",
    path: "/hairstyles/:style()",
    component: () => import("/app/pages/hairstyles/[style].vue").then(m => m.default || m)
  },
  {
    name: "hairstyles",
    path: "/hairstyles",
    component: () => import("/app/pages/hairstyles/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "previews-preview",
    path: "/previews/:preview()",
    component: () => import("/app/pages/previews/[preview].vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-user",
    path: "/profile/:user()",
    component: () => import("/app/pages/profile/[user].vue").then(m => m.default || m)
  },
  {
    name: "referred",
    path: "/referred",
    component: () => import("/app/pages/referred.vue").then(m => m.default || m)
  },
  {
    name: "request",
    path: "/request",
    component: () => import("/app/pages/request.vue").then(m => m.default || m)
  },
  {
    name: "salons",
    path: "/salons",
    component: () => import("/app/pages/salons.vue").then(m => m.default || m)
  },
  {
    name: "studio",
    path: "/studio",
    component: () => import("/app/pages/studio.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/app/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "visualizer",
    path: "/visualizer",
    component: () => import("/app/pages/visualizer.vue").then(m => m.default || m)
  }
]