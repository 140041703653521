import { createClient } from '@supabase/supabase-js'

export default defineNuxtPlugin({
  name: 'supabase',
  parallel: true,
  async setup(nuxtApp) {

    const config = useRuntimeConfig().public

    // Create a single supabase client for interacting with your database  
    const supabase = createClient(config.SUPABASE_URL, config.SUPABASE_PUBLIC_KEY)

    nuxtApp.vueApp.provide('supabase', supabase)
    nuxtApp.provide('supabase', supabase)

  }
})