<template>

  <dialog
    ref="promoCodeModal"
    id="promoCodeModal"
    class="modal modal-bottom sm:modal-middle"
    @close="modalClosed"
  >
    <div class="modal-box">
      
      <h3 class="font-bold text-xl mb-5 text-center">You've got a 10% off code!</h3>

      <p class="text-center">
        Because you arrived here from an official affiliate link, you'll get 10% off your order with the code:
      </p>

      <p class="text-center text-2xl my-3">
        <strong>{{ props.promoCode }}</strong>
      </p>

      <p
        class="text-center"
        v-if="supabaseUserData"
      >
        It'll be waiting for you in the checkout!
      </p>

      <p
        v-else
        class="text-center"
      >
        Don't forget to apply it at checkout!
      </p>

      <div class="modal-action">
        <form
          method="dialog"
          class=""
        >
          <button class="btn btn-primary">Got it!</button>
        </form>
      </div>

    </div>

    <form
      method="dialog"
      class="modal-backdrop"
    >
      <button>close</button>
    </form>

  </dialog>

</template>

<style scoped>

  
</style>

<script setup>

const supabaseUserData = useSupabaseUserData()
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  promoCode: {
    type: String,
    required: true
  },
  loggedIn: {
    type: Boolean,
    required: true
  },
})

const promoCodeModal = ref(null)

const emit = defineEmits(['closeModal'])

onMounted(()=>{
  console.log("ThePromoCodeModal mounted")
  
  setTimeout(() => {

    promoCodeModal.value.showModal()
  }, 50)
})

function modalClosed() {
  console.log("promocode modal closed")

  setTimeout(() => {
    emit('closeModal')
  }, 200)
}

</script>