

export default defineNuxtRouteMiddleware((to, from) => {

  // if we have a subdomain
  // if user is navigating to the home page, put them into hair page

  const subDomain = useSubdomain()

  // console.log('subDomain.value:', subDomain.value);

  if(to.path === '/' && subDomain.value) {
    return navigateTo('/visualizer')
  } 

  return
})

