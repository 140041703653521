
// docs
// https://github.com/Maronato/vue-toastification

import Toast from "vue-toastification/dist/index.mjs";
import "vue-toastification/dist/index.css";

export default defineNuxtPlugin(nuxtApp => {

  const options = {
    position: 'bottom-center',
    transition: "Vue-Toastification__slideBlurred",
    hideProgressBar: true,
    maxToasts: 2,
  }

  nuxtApp.vueApp.use(Toast, options)
});