
export default defineNuxtPlugin(async nuxtApp => {

  // This plugin is used to check the version of the app. When the version in the app is different from the version in the server, the app will prompt the user to update the app via a page refresh.

  // create a loop that runs every 60 seconds
  setInterval(async () => {
    // check the version of the app
    try {
      // check the version of the app
      await checkVersion()
    } catch (error) {
      console.error(`Error checking version`, error)
    }

  }, 60000)


})

