<template>
  <div>

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <ThePromoCodeModal 
      v-if="promoCodeModal.show" 
      :promoCode="affiliatePromoCode" 
      :loggedIn="promoCodeModal.loggedIn"
      @closeModal="(promoCodeModal.show = false)" 
    />

    <TheConfirmModal
      v-if="appVersionWarnModal.show" 
      :title="appVersionWarnModal.title" 
      :message="appVersionWarnModal.message"
      :confirmLabel="appVersionWarnModal.confirmLabel" 
      :confirmAction="appVersionWarnModal.confirmAction" 
      :data="appVersionWarnModal.data"
      @closeModal="ignoreRefreshWarning" 
      :ignoreLabel="appVersionWarnModal.ignoreLabel"
    />

  </div>
</template>


<style scoped>


</style>

<script setup>

const referralCode = useReferralCode()
const route = useRoute()
const authChecked = useAuthChecked()
const affiliatePromoCode = useAffiliatePromoCode()
const supabaseUserData = useSupabaseUserData()
const adminHairStyles = useAdminHairStyles()
const hairstyleTags = useHairstyleTags()
const appVersionStatus = useAppVersionStatus()
const supabaseUser = useSupabaseUser()
const isMobile = useIsMobile()

const appVersionWarnModal = ref({
  show: false,
  title: 'New version available',
  message: `We've updated the app, please refresh to get the latest version to avoid any issues.`,
  confirmLabel: 'Reload',
  ignoreLabel: 'Ignore',
  confirmAction: reloadPage,
  data: null
})

watch(appVersionStatus, (newValue) => {
  console.log('appVersionStatus changed:', newValue);
  if (appVersionStatus.value === 'warnUser') {
    appVersionWarnModal.value.show = true;
  }
});

async function reloadPage () {
  window.location.reload()
}

async function ignoreRefreshWarning () {
  appVersionWarnModal.value.show = false
  appVersionStatus.value = 'ignored'

  trackEvent({
    name: "User Ignored App Version Warning",
    userID: supabaseUser.value?.id || null
  })
}

const promoCodeModal = ref({
  show: false,
  loggedIn: false
})

// remove the listener when component is destroyed
onBeforeUnmount(() => {
  document.removeEventListener('visibilitychange', handleVisibility, false)
  window.removeEventListener('resize', () => {
    isMobile.value = window.innerWidth > 768 ? false : true;
  });
})

onMounted(async()=>{
  console.log("App mounted")
  
  
  // check if we have a login link expired error
  if(route.hash && route.hash.includes('error=unauthorized_client')) {
    console.log('route.hash:', route.hash);
    // send to auth page but carry through the route params on this page
    // using navigateTo, we'll send the user to /auth with the query param of login_link_expired=1 along with any other query params in the url

    let newQuery = Object.assign({}, route.query)
    newQuery.login_link_expired = 1

    await navigateTo({ 
      path: '/auth',
      query: newQuery 
    })
  }

  document.addEventListener('visibilitychange', handleVisibility, false)

  initApp()


  isMobile.value = window.innerWidth > 768 ? false : true;
  window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth > 768 ? false : true;
  });
})

async function handleVisibility() {
  console.log("handling Visibility")
  if (document.visibilityState === 'visible') {
    console.log("visible")
    // check if we have a session
    
    const { $supabase } = useNuxtApp()
    
    let sessionData = await $supabase.auth.getSession()
    let userData = useSupabaseUserData()

    if (sessionData.data.session && !userData.value) {

      // we've a session, but no user data, get it and start realtime listening
      const supabaseUser = useSupabaseUser()
      supabaseUser.value = sessionData.data.session.user

      identifyUser(sessionData.data.session.user)

      await getUserData(sessionData.data.session.user.id)
      
      // start listening for realtime events
      initRealtimeListening()
    }
  }
}

async function initApp() {

  console.log("initApp")

  // check for a referral code in the url
  if(route.query && route.query.referral_code) {
    console.log('route.query.referral_code:', route.query.referral_code);

    referralCode.value = route.query.referral_code
  }

  // check for a promo code in the url
  if(route.query && (route.query.ref || route.query.promo_code)) {
    console.log('route.query.promo_code:', (route.query.ref || route.query.promo_code));

    affiliatePromoCode.value = route.query.ref || route.query.promo_code

    // show the promo code modal
    promoCodeModal.value.show = true
  }

  // get the session
  const config = useRuntimeConfig().public
  const { $supabase } = useNuxtApp()
  let sessionData = await $supabase.auth.getSession()
  let userData = useSupabaseUserData()

  console.log('sessionData:', sessionData);

  // if we have a session and a refferal code, make a fetch post call to the endpoint to accept the referral
  if(referralCode.value) {
    if (sessionData.data.session) {
      // we have a session and a referral code, accept the referral
      console.log("accepting referral")
      const response = await fetch('/api/accept-referral', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          session: sessionData.data.session,
          data: {
            referral_code: referralCode.value
          }
        })
      })
      const data = await response.json()
  
      if (data.error) {
        console.log("error accepting referral:", data.message)
      } else {
        console.log("referral accepted")
        // remove the referral code from the url
        let query = Object.assign({}, to.query)
        delete query.referral_code
        router.replace({
          query: { ...query }
        })
  
        // remove the referral code from state
        referralCode.value = null
      }
    } else {
      console.log("no session yet, can't accept referral")
    }
  } else {
    console.log("no referral code")
  }

  if (sessionData.data.session && !userData.value) {

    authChecked.value = true

    // we have a session but no user data, get it. 
    const supabaseUser = useSupabaseUser()

    supabaseUser.value = sessionData.data.session.user

    identifyUser(sessionData.data.session.user)

    await getUserData(sessionData.data.session.user.id)

    // if we have a promo code, save it to the user in the affiliate_discount_code field
    if(affiliatePromoCode.value) {
      console.log("saving promo code to user")
      supabaseUserData.value.affiliate_promo_code = affiliatePromoCode.value
      await saveUserData()
    }

    // start listening for realtime events
    initRealtimeListening()

  } else {
    console.log("no session, or there is and we have user data already")

    authChecked.value = true

    let attempts = 0;
    const maxAttempts = 10;

    const intervalId = setInterval(() => {
      attempts++;

      if (window.google) {
        clearInterval(intervalId);

        window.google.accounts.id.initialize({
          client_id: config.GOOGLE_CLIENT_ID,
          callback: handleGoogleCredentialResponse,
          context: 'signin',
        })
        window.google.accounts.id.prompt()
      } else if (attempts >= maxAttempts) {
        clearInterval(intervalId);
        console.error('Google accounts library failed to load after ' + maxAttempts + ' attempts');
      }
    }, 1000); // check every second

  }

  authChecked.value = true

  // check the app version
  checkVersion()

  // Start listiening for auth state changes
  $supabase.auth.onAuthStateChange((event, session) => {
    console.log('auth state change:', event, session)
    authChecked.value = true
    console.log('autChecked.value NOW:', authChecked.value);
    if (['SIGNED_IN', 'INITIAL_SESSION'].includes(event) && session) {

      supabaseUser.value = session.user

      // we have a new session, get the user data
      getUserData(session.user.id)

      // toast.success('Welcome back')
    } else if (event === 'SIGNED_OUT') {
      // we've been signed out, clear the user data
      supabaseUserData.value = null

    }
  })

}


</script>